import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 7px;
  background: #fff0bf;
  z-index: 2;
`;

export const Progress = styled.div`
    height: 100%;
    width: ${props => (props.progress ? props.progress : 0)}%;
    background: var(--secondary);
    transition: all 0.5s;
`;
