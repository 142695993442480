import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  typeUser: 'STUDENT',
  isStudent: false,
  loading: true,
  pending: false,
  enrollment: null,
  loadingRockspot: false
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_IS_STUDENT:
      return {
        ...state,
        isStudent: action.isStudent
      };

    case actionsTypes.SET_TYPE_USER:
      return {
        ...state,
        typeUser: action.typeUser
      };

    case actionsTypes.SET_LOADING_LAYOUT:
      return {
        ...state,
        loading: action.loading
      };

    case actionsTypes.SET_PENDING:
      return {
        ...state,
        pending: action.pending
      };

    case actionsTypes.SET_ENROLLMENT:
      return {
        ...state,
        enrollment: action.enrollment
      };

    case actionsTypes.SET_LOADING_ROCKSPOT:
      return {
        ...state,
        loadingRockspot: action.loadingRockspot
      };

    default:
      return state;
  }
}
