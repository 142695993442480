import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  creditCard: {
    nomePortador: '',
    numero: '',
    cvv: '',
    validade: '',
    parcelas: 1,
    valor: 0
    //cpf: ''
  },
  creditCard2: {
    nomePortador: '',
    numero: '',
    cvv: '',
    validade: '',
    parcelas: 1,
    valor: 0
    //cpf: ''
  },
  twoCreditCards: false,
  paymentMethod: {
    creditCard: false,
    boleto: false,
    pix: true
  },
  receivingMethod: {
    inHome: false,
    inSchool: true
  },
  total: 0,
  boleto: null
};

export default function(state = INITIAL_STATE, action) {
  let valor = 0,
    virgulaFrente = true;
  switch (action.type) {
    case actionsTypes.SET_CREDIT_CARD:
      return {
        ...state,
        creditCard: action.creditCard
      };

    case actionsTypes.SET_CREDIT_CARD2:
      return {
        ...state,
        creditCard2: action.creditCard
      };

    case actionsTypes.SET_TWO_CREDIT_CARDS:
      return {
        ...state,
        twoCreditCards: action.twoCreditCards,
        creditCard: {
          ...state.creditCard,
          valor: action.twoCreditCards ? state.total / 2 : state.total
        },
        creditCard2: {
          ...state.creditCard2,
          valor: action.twoCreditCards ? state.total / 2 : 0
        }
      };

    case actionsTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod
      };

    case actionsTypes.SET_RECEIVING_METHOD:
      return {
        ...state,
        receivingMethod: action.receivingMethod
      };

    case actionsTypes.SET_TOTAL:
      return {
        ...state,
        total: action.total
      };

    case actionsTypes.SET_BOLETO:
      return {
        ...state,
        boleto: action.boleto
      };

    case actionsTypes.SET_VALOR_A_PAGAR_CARTAO:
      virgulaFrente =
        action.valor.length > 1
          ? action.valor[action.valor.length - 1] === ','
          : false;

      valor = action.valor.replace(',', '.');

      if (valor) valor = parseFloat(valor);

      return {
        ...state,
        [action.creditCard]: {
          ...state[action.creditCard],
          valor: virgulaFrente ? action.valor : valor ? valor : 0
        },
        [action.creditCard === 'creditCard' ? 'creditCard2' : 'creditCard']: {
          ...state[
            action.creditCard === 'creditCard' ? 'creditCard2' : 'creditCard'
          ],
          valor: state.total - (valor ? valor : 0)
        }
      };
    default:
      return state;
  }
}
