import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  selectedAlign: '',
  selectedBold: false,
  selectedUnderline: false,
  selectedItalic: false,
  selectedColor: '#000',
  selectedFontSize: 2,
  selectedHidable: false,
  widthContent: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SELECTED_ALIGN:
      return { ...state, selectedAlign: action.align };
    case actionsTypes.SET_SELECTED_BOLD:
      return { ...state, selectedBold: action.bold };
    case actionsTypes.SET_SELECTED_UNDERLINE:
      return { ...state, selectedUnderline: action.underline };
    case actionsTypes.SET_SELECTED_ITALIC:
      return { ...state, selectedItalic: action.italic };
    case actionsTypes.SET_SELECTED_COLOR:
      return { ...state, selectedColor: action.color };
    case actionsTypes.SET_SELECTED_FONT_SIZE:
      return { ...state, selectedFontSize: action.fontSize };
    case actionsTypes.SET_SELECTED_HIDABLE:
      return { ...state, selectedHidable: action.hidable };

    case actionsTypes.SET_WIDTH_CONTENT:
      return { ...state, widthContent: action.width };

    default:
      return state;
  }
}
