import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import actionsQuestionario from '../../actions/questionario';
import apiJoy from '../../services/apiJoy';

import ProgressBar from '../../components/Play/Visualizar/Footer/ProgressBar';

import {
  TopBar,
  NextButton,
  PreviousButton,
  FinishResearchContainer
} from './styles';
import {
  Container,
  Option,
  Options,
  Wrapper
} from '../../components/Play/Visualizar/rockfeller-content-components/QuizTypes/MultiplaEscolha/styles';

function Questionario(props) {
  const dispatch = useDispatch();
  const research = useSelector(state => state.QuestionarioReducer.research);
  const questoes = useSelector(state => state.QuestionarioReducer.questions);
  const indexQuestion = useSelector(
    state => state.QuestionarioReducer.indexQuestion
  );

  const [progress, setProgress] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [clicked, setClicked] = useState(-1);
  const [comment, setComment] = useState('');
  const [questionsCount, setQuestionsCount] = useState(0);
  const [questionResponses, setQuestionResponses] = useState({});
  const [showFinishResearch, setShowFinishResearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageFinish, setMessageFinish] = useState('');

  function handleClickOption(option, index) {
    setClicked(index);
  }

  useEffect(() => {
    async function getResearch() {
      try {
        const research = (await apiJoy.get('/student/research/list')).data;

        if (research.research) {
          dispatch(actionsQuestionario.setResearch(research));
        } else {
          props.history.push('/');
        }
      } catch (e) {
        props.history.push('/');
      }
    }
    if (!research.research) {
      getResearch();
    }
  }, [dispatch, research, props.history]);

  useEffect(() => {
    setClicked(-1);
    setComment('');
  }, [currentQuestion]);

  useEffect(() => {
    if (questionsCount !== 0 && indexQuestion !== 0) {
      const progress = ((indexQuestion + 1) * 100) / questionsCount;
      setProgress(progress);
    } else {
      setProgress(0);
    }
  }, [questionsCount, indexQuestion]);

  useEffect(() => {
    let questionsCount = 0;
    let questoes = [];
    if (research.research) {
      research.research.itens.forEach(item => {
        questionsCount += item.questions.length;
        item.questions.forEach(question => {
          questoes.push({
            ...question,
            item: { id: item.id, sequence: item.sequence, name: item.name }
          });
        });
      });
      setQuestionsCount(questionsCount);
      dispatch(actionsQuestionario.setQuestoesQuestionario(questoes));
    }
  }, [research.research, dispatch]);

  useEffect(() => {
    if (questoes.length > 0) {
      setCurrentQuestion(questoes[indexQuestion]);
    }
  }, [questoes, indexQuestion]);

  async function handleClickNext() {
    let responseOption = [];

    if (currentQuestion.options.length > 0)
      responseOption.push([
        `option[${currentQuestion.id}]`,
        currentQuestion.options[clicked].id
      ]);

    if (currentQuestion.canComment)
      responseOption.push([`comments[${currentQuestion.id}]`, comment]);

    const newState = {
      ...questionResponses,
      [currentQuestion.id]: responseOption
    };

    if (indexQuestion === questionsCount - 1) {
      const formData = [];
      Object.values(newState).forEach(responseArr => {
        formData.push(responseArr[0]);
        if (responseArr[1]) formData.push(responseArr[1]);
      });
      const requestBody = {
        id: research.research.id,
        courseClassId: research.courseClass,
        form: formData
      };

      setLoading(true);
      try {
        await apiJoy.post('/student/research/save-list', requestBody);
        setMessageFinish(research.research.messageSuccess);
      } catch (e) {
        setMessageFinish(
          'Ocorreu um erro ao tentar enviar, tente novamente mais tarde!'
        );
      }
      setLoading(false);
      setShowFinishResearch(true);
      return;
    }

    dispatch(actionsQuestionario.setQuestaoIndex(indexQuestion + 1));

    setQuestionResponses(oldState => {
      return newState;
    });
  }

  function cleanState() {
    setClicked(-1);
    setProgress(0);
    setCurrentQuestion({});
    setQuestionResponses({});
    setComment('');
    setShowFinishResearch(false);
    dispatch(actionsQuestionario.setQuestaoIndex(0));
    dispatch(actionsQuestionario.setQuestoesQuestionario([]));
    dispatch(actionsQuestionario.setResearch({}));
  }

  function handleClickBack() {
    if (indexQuestion > 0)
      dispatch(actionsQuestionario.setQuestaoIndex(indexQuestion - 1));
  }

  function redirectToDashBoard() {
    cleanState();
    props.history.push('/');
  }

  useEffect(() => {
    if (questionResponses[currentQuestion.id]) {
      if (currentQuestion.options.length > 0) {
        setClicked(questionResponses[currentQuestion.id][0][1] - 1);
      }

      if (currentQuestion.canComment) {
        if (questionResponses[currentQuestion.id].length > 1) {
          setComment(questionResponses[currentQuestion.id][1][1]);
        } else if (questionResponses[currentQuestion.id].length === 1) {
          setComment(questionResponses[currentQuestion.id][0][1]);
        }
      }
    }
  }, [questionResponses, currentQuestion]);

  return (
    <>
      {!showFinishResearch ? (
        <Container>
          <h1>{currentQuestion?.item?.name}</h1>
          <h2 style={{ textAlign: 'center' }}>{currentQuestion.name}</h2>
          <Wrapper id="wrapper">
            <Options image={false}>
              {currentQuestion?.options?.map((opcao, index, blockIndex) => {
                return (
                  <Option
                    key={opcao.id}
                    onClick={() => handleClickOption(opcao, index)}
                    className="box-shadow"
                    image={false}
                    style={{
                      background: clicked === index ? '#fff0bf' : 'none'
                    }}
                  >
                    <span>{opcao.name}</span>
                  </Option>
                );
              })}
            </Options>
          </Wrapper>
          {currentQuestion.canComment && (
            <textarea
              placeholder="comentário"
              className="form-control"
              rows={5}
              value={comment}
              onChange={e => setComment(e.target.value)}
              style={{ height: 80 }}
            />
          )}
          {currentQuestion.name && (
            <>
              <PreviousButton
                className="btn btn-default  primary border-radius"
                style={{ opacity: indexQuestion > 0 ? 1 : 0.5 }}
                onClick={handleClickBack}
              >
                Back
              </PreviousButton>
              <NextButton
                className="btn btn-default primary border-radius"
                style={{
                  opacity:
                    clicked !== -1 || currentQuestion.options.length === 0
                      ? 1
                      : 0.5
                }}
                onClick={
                  clicked !== -1 || currentQuestion.options.length === 0
                    ? handleClickNext
                    : () => {}
                }
              >
                {loading
                  ? 'Enviando...'
                  : questionsCount === indexQuestion + 1
                  ? 'Enviar'
                  : 'Next'}
              </NextButton>
            </>
          )}
        </Container>
      ) : (
        <FinishResearchContainer>
          <div>
            <h1 className="primary-text">Well Done!</h1>
            <h2>{messageFinish}</h2>
            <button
              onClick={redirectToDashBoard}
              className="btn btn-default primary border-radius"
            >
              Voltar
            </button>
          </div>
        </FinishResearchContainer>
      )}
      <TopBar>
        <ProgressBar progress={progress} />
      </TopBar>
    </>
  );
}

export default Questionario;
