import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  slide: {},
  toast: { show: false, message: '' },
  slidesCount: 0,
  numero_questao: 1,
  inInput: false,
  idQuestaoSlide: null,

  nextSlide: null,
  nextSlideCount: 1,
  nextAula: null,
  nextNumeroQuestao: 1
};

export default function(state = INITIAL_STATE, action) {
  let slide = {};
  let blocks = {};

  switch (action.type) {
    case actionsTypes.SET_SLIDE:
      return { ...state, slide: action.slide };

    case actionsTypes.SET_NEXT_SLIDE:
      return {
        ...state,
        nextSlide: action.nextSlide,
        nextAula: action.nextAula,
        nextSlideCount: action.nextSlideCount,
        nextNumeroQuestao: action.nextNumeroQuestao
      };

    case actionsTypes.SET_SLIDES_COUNT:
      return { ...state, slidesCount: action.slidesCount };

    case actionsTypes.SET_SLIDE_ID:
      slide = {
        ...state.slide,
        id: action.id
      };
      return {
        ...state,
        slide
      };

    case actionsTypes.SET_AULA_ID:
      return { ...state, aulaId: action.aulaId };

    case actionsTypes.SET_TOAST:
      return { ...state, toast: action.toast };

    case actionsTypes.SET_BLOCK:
      const slideBlocks =
        state.slide.conteudo && state.slide.conteudo.blocks
          ? state.slide.conteudo.blocks
          : {};
      blocks = {
        ...slideBlocks,
        [action.blockKey]: action.block
      };
      slide = { ...state.slide, conteudo: { ...state.slide.conteudo, blocks } };

      return { ...state, slide };

    case actionsTypes.SET_IN_INPUT:
      return { ...state, inInput: action.inInput };

    case actionsTypes.SET_NUMERO_QUESTAO:
      return { ...state, numero_questao: action.numero_questao };

    case actionsTypes.SET_STATE:
      if (action.state?.SlideReducer) return action.state.SlideReducer;
      return state;

    case actionsTypes.SET_ID_QUESTAO_SLIDE:
      return { ...state, idQuestaoSlide: action.idQuestaoSlide };

    default:
      return state;
  }
}
