import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  id: null,
  avatar_a: { url: '', id: '' },
  avatar_b: { url: '', id: '' },
  avatar_letter: 'a',
  falas_a: [
    {
      audio_url: '',
      opcoes_resposta: [{ texto: '' }],
      numero_fala: 1
    }
  ],
  falas_b: [
    {
      audio_url: '',
      opcoes_resposta: [{ texto: '' }],
      numero_fala: 1
    }
  ]
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_AVATAR:
      return {
        ...state,
        [`avatar_${state.avatar_letter}`]: action.avatar
      };
    case actionsTypes.SET_AVATAR_LETTER:
      return {
        ...state,
        avatar_letter: action.avatar_letter
      };

    case actionsTypes.SET_FALAS_TALKING:
      return {
        ...state,
        [`falas_${state.avatar_letter}`]: action.falas
      };

    case actionsTypes.SET_OPCOES_FALA_TALKING:
      return {
        ...state,
        [`falas_${state.avatar_letter}`]: [
          ...state[`falas_${state.avatar_letter}`].slice(0, action.indexFala),
          {
            ...state[`falas_${state.avatar_letter}`][action.indexFala],
            opcoes_resposta: action.opcoes
          },
          ...state[`falas_${state.avatar_letter}`].slice(action.indexFala + 1)
        ]
      };

    case actionsTypes.SET_PRACTICE:
      return { ...action.practice };

    case actionsTypes.SET_PRACTICE_DEFAULT:
      return { ...INITIAL_STATE };

    default:
      return state;
  }
}
