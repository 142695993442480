import axios from 'axios';
import apiLmsManager from './apiLmsManager';
import webhookRequest from '../utils/webhookRequest';

const apiLmsProgresso = axios.create({
  baseURL: process.env.REACT_APP_LMS_PROGRESSO_API_URL
});

function getDominio() {
  const currentUrlSplited = window.location.href.split('/');
  const dominio =
    currentUrlSplited[0] +
    '/' +
    currentUrlSplited[1] +
    '/' +
    currentUrlSplited[2];

  return dominio;
}

export function isAcessoLab() {
  const dominio = getDominio();
  let acessoLab =
    dominio === 'http://localhost:5002' ||
    dominio === 'https://rockspot.rockfeller.space' ||
    dominio === 'https://rockspot.rockfeller.com.br';

  if (!acessoLab) {
    acessoLab =
      localStorage.getItem('LAB') && localStorage.getItem('LAB') === 'true';
  }

  return acessoLab;
}

apiLmsProgresso.interceptors.request.use(async config => {
  config.headers['Content-type'] = `application/json`;

  if (config.url !== '/login_check') {
    if (!config.data) config.data = {};
    if (!config.params) config.params = {};

    const acessoLab = isAcessoLab();

    if (acessoLab) {
      config.params['lab'] = acessoLab;
      config.params['tokenLab'] = localStorage.getItem('tokenLab');
    }

    const token = localStorage.getItem('@RockfellerToken');
    token
      ? (config.headers['Authorization'] = `Bearer ${token}`)
      : (window.location.href = '/login');
  }

  return config;
});

function cleanStorage() {
  localStorage.removeItem('@RockfellerToken');
  localStorage.removeItem('@RockfellerRefreshToken');
  localStorage.removeItem('@JoyToken');
  localStorage.removeItem('@JoyRefreshToken');
  localStorage.removeItem('STUDENT_NAME');
  localStorage.removeItem('@RockfellerRefreshToken');
  localStorage.removeItem('tipo_aluno');
}
let qtdRefresh = 1;
apiLmsProgresso.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    webhookRequest(
      error,
      'Erro interno lms',
      'Erro interno no serviço: lms-conteudo'
    );

    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken')
    ) {
      try {
        const refreshToken = await apiLmsManager.post('/token/refresh', {
          refresh_token: localStorage.getItem('@RockfellerRefreshToken')
        });
        qtdRefresh++;
        localStorage.setItem('@RockfellerRefreshCount', qtdRefresh);
        if (qtdRefresh > 3) {
          cleanStorage();
          localStorage.setItem('@RockfellerRefreshCount', 0);
          localStorage.removeItem('@RockfellerRefreshCount');
          window.location.href = '/login';
        }

        if (refreshToken.status === 401 || refreshToken.status === 403) {
          localStorage.removeItem('@RockfellerToken');
          window.location.href = '/login';
        }
        localStorage.setItem('@RockfellerToken', refreshToken.data.token);
        localStorage.setItem(
          '@RockfellerRefreshToken',
          refreshToken.data.refresh_token
        );

        originalRequest._retry = true;

        originalRequest.headers.Authorization =
          'Bearer ' + refreshToken.data.token;

        return apiLmsProgresso(originalRequest);
      } catch (err) {
        cleanStorage();
        window.location.href = '/login';
        //return apiLmsProgresso(originalRequest);
      }
    } else if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      cleanStorage();
      window.location.href = '/login';
      //return apiLmsProgresso(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default apiLmsProgresso;
