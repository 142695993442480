import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../actions/Show';

import { Container, Header, Modal, Body } from '../../Modal/styles';

export default function Message() {
  const dispatch = useDispatch();
  function closeModal() {
    dispatch(actions.setShowModalMessage(false));
  }
  const show = useSelector(state => state.ShowReducer.showModalMessage);
  const message = useSelector(state => state.ShowReducer.message);

  return (
    <Container show={show}>
      <Modal>
        <Header style={{ borderBottom: 'none' }}>
          <span></span>
          <span
            className="closeButton"
            onClick={closeModal}
            style={{ marginTop: 10 }}
          >
            <FaTimes />
          </span>
        </Header>
        <Body>
          <h1
            style={{
              fontSize: 24,
              fontFamily: "'Nunito', sans-serif",
              textAlign: 'center',
              color: 'var(--primary)',
              marginTop: 20,
              paddingBottom: 40
            }}
          >
            {message}
          </h1>
        </Body>
      </Modal>
    </Container>
  );
}
