import styled from 'styled-components';

export const TopBar = styled.div`
  position: absolute;
  top: 0;
  left: 120px;
  width: calc(100% - 120px);
  @media only screen and (max-width: 576px) {
    width: 100%;
    left: 0;
  }
`;

export const NextButton = styled.div`
  margin-top: 20px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  position: absolute;
  bottom: -80px;
`;

export const PreviousButton = styled.div`
  margin-top: 20px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  position: absolute;
  bottom: -80px;
`;

export const FinishResearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0;
  padding-left: 120px;
  @media only screen and (max-width: 576px) {
    padding-left: 0px;
  }
  h1{
    font-size: 24px;
    text-align: center;
  }
  h2{
    font-size: 21px;
    margin-top: 18px;
    color: #4a4a4a;
    text-align: center;
  }
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  button{
    margin-top: 18px;
  }
`;