import { takeLatest, select } from 'redux-saga/effects';
import actionsTypes from './constants/ActionsTypes';
import {stringify} from "flatted";

function* mySaga() {
  yield takeLatest('*', onAllAction);
}

function* onAllAction(action) {
  if (action.fromTeacher) return;
  if (action.fromStudent) return;

  if (
    action.type === actionsTypes.SET_SLIDE ||
    action.type === actionsTypes.SET_SLIDES_COUNT ||
    action.type === actionsTypes.SET_AULA ||
    action.type === actionsTypes.SET_AULA_PATH ||
    action.type === actionsTypes.SET_AULA_ID ||
    action.type === actionsTypes.SET_DEFAULT_BACKGROUND ||
    action.type === actionsTypes.SET_LIVRO_ID ||
    action.type === actionsTypes.SET_LIVRO_CORES ||
    action.type === actionsTypes.SET_SIZE_CONTENT ||
    action.type === actionsTypes.RESET_CONTENT_SCALE ||
    action.type === actionsTypes.RESET_PLAYER_SCORE ||
    action.type === actionsTypes.SET_IS_LIVE ||
    action.type === actionsTypes.SET_INDICE_AUDIO ||
    action.type === actionsTypes.SET_TOTEM_DRAG ||
    action.type === actionsTypes.SET_DICE_NUMBER ||
    action.type === actionsTypes.CHANGE_IMAGE_CARD ||
    action.type === actionsTypes.SET_IS_ONLINE_TEACHER ||
    action.type === actionsTypes.CHANGE_SLIDE ||
    action.type === actionsTypes.SET_CONNECTION_INTERNET ||
    action.type === actionsTypes.SET_ID_QUESTAO_SLIDE ||
    action.type === actionsTypes.SET_SHOW_CONFIG_MICROPHONE ||
    action.type === actionsTypes.SET_MICROPHONE ||
    action.type === actionsTypes.SET_IS_LIVEBRIDGE_LOADED ||
    action.type === actionsTypes.SET_TEACHER ||
    action.type === actionsTypes.UPDATE_BLOCK_HISTORY ||
    action.type === actionsTypes.SET_CLICK_INDEX_REMOTE ||
      action.type === actionsTypes.ADD_BLOCK_TEXT ||
      action.type === actionsTypes.SET_BLOCK_POSITION ||
      action.type === actionsTypes.SET_ARROW_BLOCK_POSITION ||
      action.type === actionsTypes.SET_BLOCK_SIZE ||
      action.type === actionsTypes.SET_BLOCK_INDEX ||
      action.type === actionsTypes.ADD_BLOCK_SELECTED ||
      action.type === actionsTypes.DELETE_BLOCK ||
      action.type === actionsTypes.DELETE_ALL_BLOCK ||
      action.type === actionsTypes.DELETE_SELECTED_BLOCK ||
      action.type === actionsTypes.ADD_BLOCK_TEXT ||
      action.type === actionsTypes.SET_HIDE_BLOCK_POSITION ||
      action.type === actionsTypes.SET_HIDE_BLOCK_SIZE ||
      action.type === actionsTypes.COPY_BLOCKS ||
      action.type === actionsTypes.PASTE_BLOCKS ||
      action.type === actionsTypes.SET_BLOCK ||
      action.type === actionsTypes.RESET_BLOCKS_SELECTED ||
      action.type === actionsTypes.SET_CONTROL_IS_PRESSED ||
      action.type === actionsTypes.SET_IN_INPUT ||
      action.type === actionsTypes.SET_CONTENT ||
      action.type === actionsTypes.SET_HTML ||
      action.type === actionsTypes.SET_COLUMN_HTML ||
      action.type === actionsTypes.SET_COLUMN_CONTENT ||
      action.type === actionsTypes.UNDO ||
      action.type === actionsTypes.REDO ||
      action.type === actionsTypes.UPDATE_BLOCK_HISTORY ||
    action.type === actionsTypes.SET_PLAYED_BLOCK_KEY
  )
    return;

  if (action.type === actionsTypes.SET_PLAYER_SECONDS && action.isChangeSlider) {
    return;
  }

  const state = yield select();

  const student = state.SocketReducer.student;

  if (!student || !student.getStreamManager()) {
    return;
  }

  if (!state.AulaReducer.isLive) return;
  if (!state.AulaReducer.room) return;

  if (localStorage.getItem('isRunAction') === 'false') return;

  if (!state.AulaReducer.isControlRemote) return;

  const slideId = state.SlideReducer.slide.id;
  action['fromStudent'] = true;

  let isSaveAction = true;
  if (
    action.type === actionsTypes.SET_PLAY_BLOCK_KEY ||
    action.type === actionsTypes.SET_STATE_PLAY_AUDIOS ||
    action.type === actionsTypes.SET_IS_LISTEN_AUDIOS_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_TEACHER ||
    action.type === actionsTypes.SET_UPDATE_STUDENT_INDICE_AUDIO ||
    action.type === actionsTypes.SET_TOTEM_POSITION_STUDENT ||
    action.type === actionsTypes.SET_TOTEM_POSITION_TEACHER ||
    action.type === actionsTypes.SET_IS_ROLL_DICE_TEACHER ||
    action.type === actionsTypes.SET_PLAYER_SECONDS
  ) {
    isSaveAction = false;
  }

  if (
    action.type === actionsTypes.SET_PLAY_BLOCK_KEY ||
    action.type === actionsTypes.SET_PLAYER_SECONDS ||
    action.type === actionsTypes.SET_IS_LISTEN_AUDIOS_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_STUDENT ||
    action.type === actionsTypes.SET_IS_PLAYING_TEACHER
  ) {
    console.log(action);
  }

  const data = {
    slideId,
    action,
    isSaveAction
  };

  student.getStreamManager().stream.session.signal({
    data: stringify(data),
    type: 'run-action',
  });
}

export default mySaga;
