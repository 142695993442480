import axios from 'axios';
import webhookRequest from '../utils/webhookRequest';
const apiLmsManager = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_LMS_MANAGER_API_URL_DEV
      : process.env.REACT_APP_LMS_MANAGER_API_URL
});

apiLmsManager.interceptors.request.use(async config => {
  config.headers['Content-type'] = `application/json`;

  if (
    config.url !== '/login_check' &&
    config.url !== '/reset-password' &&
    config.url !== '/reset-password/alterar-senha' &&
    config.url !== '/empresa/logo' &&
    !config.url.includes('/ativacoes-livro')
  ) {
    const token = localStorage.getItem('@RockfellerToken');
    token
      ? (config.headers['Authorization'] = `Bearer ${token}`)
      : (window.location.href = '/login');
  }

  return config;
});

function cleanStorage() {
  localStorage.removeItem('@RockfellerToken');
  localStorage.removeItem('@RockfellerRefreshToken');
  localStorage.removeItem('@JoyToken');
  localStorage.removeItem('@JoyRefreshToken');
  localStorage.removeItem('STUDENT_NAME');
  localStorage.removeItem('@RockfellerRefreshCount');
  localStorage.removeItem('tipo_aluno');
  localStorage.removeItem('LOGO');
  localStorage.removeItem('LOGO_LOGIN');
}

apiLmsManager.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    webhookRequest(
      error,
      'Erro interno lms',
      'Erro interno no serviço: lms-manager'
    );
    const originalRequest = error.config;
    if (error.response.config.url === '/login_check')
      return Promise.reject(error);

    if (
      error.response.status === 401 &&
      error.response.config.url === '/token/refresh'
    ) {
      localStorage.removeItem('@RockfellerToken');
      localStorage.removeItem('@RockfellerRefreshToken');
      window.location.href = '/login';
    } else if (
      error.response.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken')
    ) {
      try {
        const refreshToken = await apiLmsManager.post('/token/refresh', {
          refresh_token: localStorage.getItem('@RockfellerRefreshToken')
        });

        if (refreshToken.status === 401 || refreshToken.status === 403) {
          cleanStorage();
          window.location.href = '/login';
        }
        localStorage.setItem('@RockfellerToken', refreshToken.data.token);
        localStorage.setItem(
          '@RockfellerRefreshToken',
          refreshToken.data.refresh_token
        );

        originalRequest._retry = true;

        originalRequest.headers.Authorization =
          'Bearer ' + refreshToken.data.token;

        localStorage.removeItem('LOGO');
        localStorage.removeItem('LOGO_LOGIN');
        return apiLmsManager(originalRequest);
      } catch (err) {
        cleanStorage();
        window.location.href = '/login';
      }
    } else if (error.response.status === 401 || error.response.status === 403) {
      cleanStorage();
      window.location.href = '/login';
      //return apiLmsManager(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default apiLmsManager;
