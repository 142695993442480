import React, { lazy, Suspense } from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from '../components/AlertTemplate';
import Questionario from '../pages/Questionario';
//import LiveAula from '../pages/LiveAula';

const AppLayout = lazy(() => import('./../layouts/App'));
const AppLayoutTeste = lazy(() => import('./../layouts/App/teste'));
const AuthLayout = lazy(() => import('./../layouts/Auth'));
const AulaLayout = lazy(() => import('./../layouts/Aula'));
const LojaLayout = lazy(() => import('./../layouts/Loja'));

const Login = lazy(() => import('../pages/Auth/Login'));
const RecuperarSenha = lazy(() => import('../pages/Auth/RecuperarSenha'));
const AlterarSenha = lazy(() => import('../pages/Auth/AlterarSenha'));
const Dashboard = lazy(() => import('../pages/Dashboard'));
const Play = lazy(() => import('../pages/Play'));
const Loja = lazy(() => import('../pages/Loja'));
const Carrinho = lazy(() => import('../pages/Loja/Carrinho'));
const Pagamento = lazy(() => import('../pages/Loja/Pagamento'));
const Confirmacao = lazy(() => import('../pages/Loja/Confirmacao'));
const ConfirmacaoPix = lazy(() => import('../pages/Loja/ConfirmacaoPix'));
const PedidoConfirmado = lazy(() => import('../pages/Loja/PedidoConfirmado'));
const MinhasCompras = lazy(() => import('../pages/Loja/MinhasCompras'));
const ClassRoom = lazy(() => import('../pages/ClassRoom'));
const Aula = lazy(() => import('../pages/Play/Aula'));
const Financeiro = lazy(() => import('../pages/Financeiro'));
const Boletim = lazy(() => import('../pages/Boletim'));
const NovoAgendamento = lazy(() =>
  import('../pages/Agendamento/NovoAgendamento')
);
const Agendamento = lazy(() =>
  import('../pages/Agendamento/TodosAgendamentos')
);
const Suporte = lazy(() => import('../pages/Suporte'));
const CancelarMatricula = lazy(() => import('../pages/CancelarMatricula'));
const MaterialApoio = lazy(() => import('../pages/Play/MaterialApoio'));
const BingItOut = lazy(() => import('../pages/Play/BringItOut'));
const Redacao = lazy(() => import('../pages/Play/BringItOut/Redacao'));
const ConfiguraLab = lazy(() => import('../pages/ConfiguraLab'));
const LiveAula = lazy(() => import('../pages/LiveAula'));
const Teste = lazy(() => import('../pages/LiveAula/teste'));

const ChooseBooks = lazy(() =>
  import('../components/Dashboard/DashboardLive/pages/ChooseBooks')
);

const Payment = lazy(() =>
  import('../components/Dashboard/DashboardLive/pages/Payment')
);

const PaymentConfirm = lazy(() =>
  import('../components/Dashboard/DashboardLive/pages/PaymentConfirm')
);
const AtivarLivro = lazy(() => import('../pages/AtivarLivro'));
const AtivarLivroCadastrar = lazy(() => import('../pages/AtivarLivro/Cadastrar'));
const AtivarLivroCodigoAtivado = lazy(() => import('../pages/AtivarLivro/CodigoAtivado'));

const AppRoute = ({ component: Component, layout: Layout, title, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout title={title}>
        <Component {...props}></Component>
      </Layout>
    )}
  ></Route>
);

function Router() {
  const options = {
    // you can also just use 'bottom center'
    position: positions.TOP_RIGHT,
    timeout: 5000,
    // you can also just use 'scale'
    transition: transitions.SCALE,
    containerStyle: {
      zIndex: 99999999999,
      textAlign: 'justify'
    }
  };

  //useDeepLink();
  return (
    <Suspense fallback={<div>Carregando...</div>}>
      <AlertProvider template={AlertTemplate} {...options} type={'info'}>
        <BrowserRouter>
          <AppRoute path="/login" exact layout={AuthLayout} component={Login} />
          <AppRoute
            path="/recuperar-senha"
            exact
            layout={AuthLayout}
            component={RecuperarSenha}
          />
          <AppRoute
            path="/alterar-senha/:reset_token"
            exact
            layout={AuthLayout}
            component={AlterarSenha}
          />
          <AppRoute path="/" exact layout={AppLayout} component={Dashboard} />

          <AppRoute
            path="/escolha-livro"
            exact
            layout={AppLayout}
            component={ChooseBooks}
          />

          <AppRoute
            path="/pagamento-curso"
            exact
            layout={AppLayout}
            component={Payment}
          />

          <AppRoute
            path="/confirma-pagamento-curso"
            exact
            layout={AppLayout}
            component={PaymentConfirm}
          />

          <AppRoute path="/play" exact layout={AppLayout} component={Play} />
          <Route
            path="/live-aula"
            exact
            layout={AppLayout}
            component={LiveAula}
          />
          <Route
            path="/teste"
            exact
            layout={AppLayoutTeste}
            component={Teste}
          />
          <AppRoute path="/loja" exact layout={LojaLayout} component={Loja} />
          <AppRoute
            path="/loja/carrinho"
            exact
            layout={LojaLayout}
            component={Carrinho}
          />
          <AppRoute
            path="/loja/pagamento"
            exact
            layout={LojaLayout}
            component={Pagamento}
          />
          <AppRoute
            path="/loja/confirmacao"
            exact
            layout={LojaLayout}
            component={Confirmacao}
          />
          <AppRoute
            path="/loja/confirmacao-pix"
            exact
            layout={LojaLayout}
            component={ConfirmacaoPix}
          />
          <AppRoute
            path="/loja/pedido-confirmado"
            exact
            layout={LojaLayout}
            component={PedidoConfirmado}
          />
          <AppRoute
            path="/loja/minhas-compras"
            exact
            layout={LojaLayout}
            component={MinhasCompras}
          />
          <AppRoute
            path="/material-apoio/:mapaId"
            exact
            layout={AppLayout}
            component={MaterialApoio}
          />

          <AppRoute
            path="/play/aula/:slideId"
            exact
            layout={AulaLayout}
            component={Aula}
          />

          <AppRoute
            exact
            path="/class-room"
            layout={AppLayout}
            component={ClassRoom}
            title="Minha sala de aula"
          />

          <AppRoute
            exact
            path="/financeiro"
            layout={AppLayout}
            component={Financeiro}
            title="Financeiro"
          />
          <AppRoute
            exact
            path="/agendamento"
            layout={LojaLayout}
            component={Agendamento}
            title="Agendamento"
          />

          <AppRoute
            exact
            path="/new-agendamento"
            layout={LojaLayout}
            component={NovoAgendamento}
            title="Agendamento"
          />

          <AppRoute
            exact
            path="/boletim"
            layout={AppLayout}
            component={Boletim}
            title="Boletim"
          />

          <AppRoute
            exact
            path="/suporte"
            layout={AppLayout}
            component={Suporte}
            title="Suporte"
          />

          <AppRoute
            exact
            path="/cancelar-matricula"
            layout={AppLayout}
            component={CancelarMatricula}
            title="Cancelar Matrícula"
          />

          <AppRoute
            path="/bring-it-out"
            exact
            layout={AppLayout}
            component={BingItOut}
          />

          <AppRoute
            path="/bring-it-out/:idSchedule"
            exact
            layout={AppLayout}
            component={Redacao}
          />
          <AppRoute
            path="/configura-lab"
            exact
            layout={AppLayout}
            component={ConfiguraLab}
          />

          <AppRoute
            path="/questionario"
            exact
            layout={AppLayout}
            component={Questionario}
          />

          <AppRoute
            path="/ativar-livro"
            exact
            layout={AppLayout}
            component={AtivarLivro}
          />
          
          <AppRoute
            path="/ativar-livro/:codigo/cadastrar"
            exact
            layout={AppLayout}
            component={AtivarLivroCadastrar}
          />

          <AppRoute
            path="/ativar-livro/:codigo/codigo-ativado"
            exact
            layout={AppLayout}
            component={AtivarLivroCodigoAtivado}
          />
        </BrowserRouter>
      </AlertProvider>
    </Suspense>
  );
}

export default Router;
