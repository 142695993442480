import Axios from 'axios';

const WEBHOOK_TEAMS_URL =
  'https://rockfellercombr.webhook.office.com/webhookb2/c23eee01-e605-4c32-b048-c7785d58c14f@4498b01a-0d3a-45c5-81ac-bb3ae918283e/IncomingWebhook/fa3a3538ec7b4430add1bac23866f25a/99ee0f95-0ff1-4462-86d4-f116e96cf59e';

export default function webhookRequest(
  error,
  summary = 'Erro interno do joy',
  activityTitle = 'Erro interno do joy'
) {
  if (error.response?.status >= 500) {
    try {
      Axios.post(WEBHOOK_TEAMS_URL, {
        '@type': 'MessageCard',
        '@context': 'http://schema.org/extensions',
        themeColor: '0076D7',
        summary,
        sections: [
          {
            activityTitle,
            activitySubtitle: `Nome do aluno: ${localStorage.getItem(
              'STUDENT_NAME'
            )} \nResposta do servidor: ${JSON.stringify(error.response?.data)}`,
            markdown: true
          }
        ]
      });
    } catch (err) {}
  }
}
