import axios from 'axios';
import webhookRequest from '../utils/webhookRequest';
//import apiLmsManager from './apiLmsManager';

const apiJoy = axios.create({
  baseURL: process.env.REACT_APP_JOY_API_URL
});

apiJoy.interceptors.request.use(async config => {
  config.headers['Content-type'] = `application/json`;
  config.timeout = 5000;
  if (config.url !== '/login' && config.url !== '/users/resetting-password') {
    const token =
      localStorage.getItem('@JoyToken') &&
      localStorage.getItem('@JoyToken') !== 'undefined'
        ? localStorage.getItem('@JoyToken')
        : localStorage.getItem('@RockfellerToken');

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  return config;
});

apiJoy.interceptors.response.use(
  async response => {
    return response;
  },
  async error => {
    webhookRequest(error);
    return Promise.reject(error);
    /*const originalRequest = error.config;

    if (localStorage.getItem('@RockfellerRefreshToken') === 'undefined')
      return console.log(error);

    if (
      error.response?.status === 401 &&
      localStorage.getItem('@RockfellerRefreshToken') &&
      localStorage.getItem('@RockfellerRefreshToken') !== 'undefined'
    ) {
      try {
        const response = await apiLmsManager.post('/user/refresh-token', {
          refresh_token: localStorage.getItem('@RockfellerRefreshToken')
        });
        localStorage.setItem('@RockfellerToken', response.data.token_erp);
        localStorage.setItem(
          '@RockfellerRefreshToken',
          response.data.refresh_token_erp
        );
        originalRequest._retry = true;

        originalRequest.headers.Authorization =
          'Bearer ' + response.data.token_erp;

        return apiJoy(originalRequest);
      } catch (err) {
        console.log(err);
        localStorage.removeItem('@RockfellerToken');
        localStorage.removeItem('@RockfellerRefreshToken');
      }
    } else if (
      error.response?.status === 401 ||
      error.response?.status === 403
    ) {
      localStorage.removeItem('@RockfellerToken');
      try {
        const response = await apiLmsManager.get('/user');
        localStorage.setItem('@RockfellerToken', response.data.token_erp);
        localStorage.setItem(
          '@RockfellerRefreshToken',
          response.data.refresh_token_erp
        );
        localStorage.setItem('STUDENT_NAME', response.data.name);
        localStorage.setItem('USER_TYPE', response.data.type_value);

        originalRequest._retry = true;
        originalRequest.headers.Authorization =
          'Bearer ' + response.data.token_erp;

        return apiJoy(originalRequest);
      } catch (err) {
        console.log(err);
      }
    }

    return Promise.reject(error);*/
  }
);

export default apiJoy;
