import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  research: {},
  indexQuestion: 0,
  questions: [],
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_RESEARCH:
        return {
            ...state,
            research: action.research
        }
    case actionsTypes.SET_INDEX_QUESTION:
      return {
        ...state,
        indexQuestion: action.indexQuestion
      }

    case actionsTypes.SET_QUESTOES_QUESTIONARIO:
      return {
        ...state,
        questions: action.questions
      }


    default:
      return state;
  }
}
