import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  schedule: {},
  courseClass:null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_SCHEDULE:
      return { ...state, schedule: action.schedule };

    case actionsTypes.SET_COURSE_CLASS:
      return { ...state, courseClass: action.courseClass };

    default:
      return state;
  }
}
