import React, { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './styles/global';
import Router from './routes';
import ModalDesconnect from './components/Modals/Desconnect';
import ModalMessage from './components/Modals/Message';
import useStateTheme from './hooks/useStateTheme';
import { isIEA } from './utils/isIEA';

const queryClient = new QueryClient();
function App() {
  const [theme] = useStateTheme();

  useEffect(() => {
    if (isIEA()) document.title = 'IEA Play';
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Router />
        <ModalDesconnect />
        <ModalMessage />
        <GlobalStyle />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
