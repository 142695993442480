import { useEffect, useState } from "react";
import themes from "../styles/themes";
import { useSelector } from "react-redux";

function useStateTheme() {
  const { method } = useSelector(state => state.UserReducer);
  const [theme, setTheme] = useState(themes.default);

  function updateTheme(method) {
    if (!method) {
      setTheme(themes.default);
      return;
    }

    switch (method.toLowerCase()) {
      case 'online':
        setTheme(themes.online);
        break;
      case 'presencial':
        setTheme(themes.presencial);
        break;
      case 'live':
        setTheme(themes.live);
        break;
      default:
        setTheme(themes.default);
        break;
    }
  }

  useEffect(() => {
    updateTheme(method);
  }, [method]);

  return [theme, setTheme];
}

export default useStateTheme;