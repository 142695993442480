import actionsTypes from '../constants/ActionsTypes';

const INITIAL_STATE = {
  totensPositions: {}
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_TOTEM_POSITION:
      return {
        ...state,
        totensPositions: {
          ...state.totensPositions,
          [action.totemKey]: action.position
        }
      };

    case actionsTypes.SET_STATE:
      if (action.state?.DragnDropReducer) return action.state.DragnDropReducer;
      return state;

    default:
      return state;
  }
}
