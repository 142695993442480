import actionsTypes from '../constants/ActionsTypes';

const actions = {
  toggleShowModalBackground: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_BACKGROUND
  }),
  toggleShowModalImage: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_IMAGE
  }),
  toggleShowModalTable: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_TABLE
  }),
  toggleShowModalVideo: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_VIDEO
  }),
  toggleShowModalAudio: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_AUDIO
  }),
  toggleShowModalCronometro: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CRONOMETRO
  }),
  toggleFullScreen: () => ({
    type: actionsTypes.TOGGLE_FULL_SCREEN
  }),
  toggleShowModalQuiz: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_QUIZ
  }),
  toggleShowModalTalking: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_TALKING
  }),
  toggleShowLayoutSelector: () => ({
    type: actionsTypes.TOGGLE_SHOW_LAYOUT_SELECTOR
  }),
  toggleShowGameToolsSelector: () => ({
    type: actionsTypes.TOGGLE_SHOW_GAMETOOLS_SELECTOR
  }),
  setShowTextTools: show => ({
    type: actionsTypes.SET_SHOW_TEXT_TOOLS,
    show
  }),
  toggleShowModalControls: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CONTROLS
  }),
  toggleShowModalAddCurso: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_CURSO
  }),
  toggleShowModalAddDivisao: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_DIVISAO
  }),
  toggleShowModalAddLivro: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_LIVRO
  }),
  toggleShowModalAddItem: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ADD_ITEM
  }),
  toggleShowJanelaDireita: () => ({
    type: actionsTypes.TOGGLE_SHOW_JANELA_DIREITA
  }),
  toggleShowModalClickable: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CLICKABLE
  }),
  toggleShowImageConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_IMAGE_CONFIG
  }),
  toggleShowAudioConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_AUDIO_CONFIG
  }),
  toggleShowVideoConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_VIDEO_CONFIG
  }),
  toggleShowClickableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_CLICKABLE_CONFIG
  }),
  toggleShowSlideConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_SLIDE_CONFIG
  }),
  toggleShowModalLayoutConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_LAYOUT_CONFIG
  }),
  toggleShowModalExcluir: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_EXCLUIR
  }),
  toggleShowBlankBoard: () => ({
    type: actionsTypes.TOGGLE_SHOW_BLANK_BOARD
  }),
  setShowCardToast: (
    show,
    tipo,
    text,
    voice = false,
    points = 0,
    soundFeedback = true,
    timeoutToast = null
  ) => ({
    type: actionsTypes.SET_SHOW_CARD_TOAST,
    show,
    tipo,
    text,
    voice,
    points,
    soundFeedback,
    timeoutToast
  }),
  toggleShowTextConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TEXT_CONFIG
  }),
  toggleShowInputConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_INPUT_CONFIG
  }),
  toggleShowTableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TABLE_CONFIG
  }),
  toggleShowModalClonarAula: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_CLONAR_AULA
  }),
  toggleShowGaleriaImagens: () => ({
    type: actionsTypes.TOGGLE_SHOW_GALERIA_IMAGENS
  }),
  toggleShowGaleriaAvatares: () => ({
    type: actionsTypes.TOGGLE_SHOW_GALERIA_AVATARES
  }),
  toggleShowDiceConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DICE_CONFIG
  }),
  toggleShowGameCardConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_GAME_CARD_CONFIG
  }),
  toggleShowRandomCardConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_RANDOMCARD_CONFIG
  }),
  toggleShowExtraWord: () => ({
    type: actionsTypes.TOGGLE_SHOW_EXTRA_WORD
  }),
  toggleShowCronometroConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_CRONOMETRO_CONFIG
  }),
  toggleShowLoading: () => ({
    type: actionsTypes.TOGGLE_SHOW_LOADING
  }),
  toggleShowTotemConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_TOTEM_CONFIG
  }),
  toggleShowTotem: () => ({
    type: actionsTypes.TOGGLE_SHOW_TOTEM
  }),
  toggleShowDropableConfig: () => ({
    type: actionsTypes.TOGGLE_SHOW_DROPABLE_CONFIG
  }),
  toggleShowModalUnits: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_UNITS
  }),
  toggleShowModalLivros: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_LIVROS
  }),
  toggleShowModalEnviarProfessor: () => ({
    type: actionsTypes.TOGGLE_SHOW_MODAL_ENVIAR_PROFESSOR
  }),
  setShowModalDesconnect: (show = false, message = null) => ({
    type: actionsTypes.SET_SHOW_MODAL_DESCONNECT,
    show,
    message
  }),
  setShowModalMessage: (show = false, message = null) => ({
    type: actionsTypes.SET_SHOW_MODAL_MESSAGE,
    show,
    message
  }),
  setHideHidables: hideHidables => ({
    type: actionsTypes.SET_HIDE_HIDABLES,
    hideHidables
  }),
  setFinishLesson: (finishLesson = false) => ({
    type: actionsTypes.SET_FINISH_LESSON,
    finishLesson
  }),
  toggleShowBoard: boardTransparent => ({
    type: actionsTypes.TOGGLE_SHOW_BOARD,
    boardTransparent
  }),
  closeBoard: () => ({
    type: actionsTypes.CLOSE_BOARD
  }),
  setSizeBoard: (widthBoard = null, heightBoard = null) => ({
    type: actionsTypes.SET_SIZE_BOARD,
    widthBoard,
    heightBoard
  }),
  setBoardTopDistance: boardTopDistance => ({
    type: actionsTypes.SET_BOARD_TOP_DISTANCE,
    boardTopDistance
  })
};
export default actions;
