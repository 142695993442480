export function isIEA() {
    const hostname = window.location.hostname;
    const port = window.location.port;

    if (hostname === 'localhost' && `${hostname}:${port}` === process.env.REACT_APP_DOMAIN_IEA) {
        return true;
    } else if (hostname === process.env.REACT_APP_DOMAIN_IEA)
        return true;

    return false;
}