import actionsTypes from '../constants/ActionsTypes';

const tempoAcesso = localStorage.getItem('@RockfellerTimer')
  ? parseInt(localStorage.getItem('@RockfellerTimer'))
  : null;

const INITIAL_STATE = {
  user: {},
  tempo_acesso: tempoAcesso,
  method: localStorage.getItem('@RockfellerMethod')
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionsTypes.SET_USER:
      return {
        ...state,
        user: action.user
      };
    case actionsTypes.SET_TEMPO_ACESSO_USER:
      localStorage.setItem('@RockfellerTimer', action.tempo_acesso);
      return {
        ...state,
        tempo_acesso: action.tempo_acesso
      };

    case actionsTypes.SET_USER_METHOD:
      localStorage.setItem('@RockfellerMethod', action.method ? action.method : 'presencial');
      return {
        ...state,
        method: action.method
      };

    case actionsTypes.DECREMENTA_TEMPO_ACESSO_USER:
      if (state.tempo_acesso - action.tempo <= 1) {
        clearInterval(action.timerInterval);

        return {
          ...state,
          tempo_acesso: null
        };
      }

      return {
        ...state,
        tempo_acesso: state.tempo_acesso - action.tempo
      };

    default:
      return state;
  }
}
