import actionsTypes from '../constants/ActionsTypes';

const actionsQuestionario = {
  setResearch: research => ({
    type: actionsTypes.SET_RESEARCH,
    research
  }),
  setQuestaoIndex: indexQuestion => ({
    type: actionsTypes.SET_INDEX_QUESTION,
    indexQuestion
  }),
  setQuestoesQuestionario: questions => ({
    type: actionsTypes.SET_QUESTOES_QUESTIONARIO,
    questions
  })
};

export default actionsQuestionario;
