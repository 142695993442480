import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../actions/Show';

import { Container, Header, Modal, Body } from '../../Modal/styles';

export default function Units() {
  const dispatch = useDispatch();
  function closeModal() {
    dispatch(actions.setShowModalDesconnect(false));
    window.location.href = '/login';
  }
  const show = useSelector(state => state.ShowReducer.showModalDesconnect);
  const messageDisconnect = useSelector(state => state.ShowReducer.message);

  return (
    <Container show={show}>
      <Modal>
        <Header style={{ borderBottom: 'none' }}>
          <span></span>
          <span
            className="closeButton"
            onClick={closeModal}
            style={{ marginTop: 10 }}
          >
            <FaTimes />
          </span>
        </Header>
        <Body>
          <h1
            style={{
              fontSize: 24,
              fontFamily: "'Nunito', sans-serif",
              textAlign: 'center',
              color: 'var(--primary)'
            }}
          >
            Você foi desconectado.
          </h1>
          <p
            style={{
              fontSize: 18,
              fontFamily: "'Nunito', sans-serif",
              textAlign: 'center',
              color: 'var(--primary)',
              marginTop: 20,
              paddingBottom: 40
            }}
          >
            {messageDisconnect}
          </p>
        </Body>
      </Modal>
    </Container>
  );
}
