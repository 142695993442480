import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
  .css-2b097c-container {
    width: 200px;
  }
  h1 {
    width: 100%;
    font-size: ${props =>
      props.getRelativeSizeInPx ? props.getRelativeSizeInPx(18) : 21}px;
    line-height: 29px;
    color: #4a4a4a;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    padding: ${props =>
        props.getRelativeSizeInPx ? props.getRelativeSizeInPx(15) : 20}px
      0
      ${props =>
        props.getRelativeSizeInPx ? props.getRelativeSizeInPx(15) : 20}pxpx
      0;
    font-weight: bold;
  }
  h2 {
    text-align: center;
    width: 80%;
    margin-left: calc(
      ${props =>
          props.getRelativeSizeInPx ? props.getRelativeSizeInPx(25) : 20}px +
        10%
    );
    font-size: ${props =>
      props.getRelativeSizeInPx ? props.getRelativeSizeInPx(18) : 21}px;
    color: #4a4a4a;
    font-style: normal;
    font-weight: normal;
    margin-bottom: ${props =>
      props.getRelativeSizeInPx ? props.getRelativeSizeInPx(20) : 30}px;
    margin-top: ${props =>
      props.getRelativeSizeInPx ? props.getRelativeSizeInPx(20) : 30}px;
  }
`;

export const Options = styled.div`
  display: flex;
  width: ${props => (props.image ? '70%' : '80%')};
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  input[type='radio'] {
    display: none;
  }
`;

export const Option = styled.div`
  display: flex;
  width: ${props => (props.image ? '90%' : '45%')};
  justify-content: space-around;
  align-items: center;
  margin-bottom: ${props =>
    props.getRelativeSizeInPx ? props.getRelativeSizeInPx(15) : 20}px;
  background: ${props =>
    props.clicked
      ? props.corPrimaria
        ? props.corPrimaria
        : '#d8d8d8'
      : '#fff'};
  border-radius: 8px;
  border: 1px solid
    ${props =>
      props.clicked
        ? props.corPrimaria
          ? props.corPrimaria
          : '#d8d8d8'
        : '#979797'};
  height: ${props =>
    props.getRelativeSizeInPx ? props.getRelativeSizeInPx(50) : 71}px;
  line-height: 22px;
  font-size: ${props =>
    props.getRelativeSizeInPx ? props.getRelativeSizeInPx(13) : 15}px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s;
  color: ${props =>
    props.clicked && props.corTextoPrimaria && props.corTextoPrimaria};
  span {
    font-size: ${props =>
      props.getRelativeSizeInPx ? props.getRelativeSizeInPx(15) : 18}px;
    margin: 0;
    width: 100%;
    text-align: center;
  }
  &:active {
    background: #d8d8d8;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
